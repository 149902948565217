@import url('https://fonts.googleapis.com/css?family=Ubuntu');

html, body {width: auto!important; overflow-x: hidden!important; height: auto!important}

#root {
  height: 100% !important;
}

html {
  font-size: 1rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.4rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.6rem;
  }
}

:root {
  --primary-theme-color: #A648E8;
  --secondary-theme-color: #fff;
  --lighter-theme-color: #bc88e2;
  --darker-theme-color: #9507fa;
  --black-color: #000000;
  --font-style: 'Ubuntu', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lead {
  font-size: 1.5rem;
  font-family: var(--font-style);
  margin-bottom: 0;
}

#about-page .lead {
  font-size: 1.2rem;
}

h3 {
  font-size: 1.5rem!important;
  font-family: var(--font-style);
}

#privacy-policy-page .privacy-content, .privacy-policy, .vision {
  font-family: var(--font-style);
}


#subscription .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

#subscription .btn-primary, #contact-form .btn-primary {
  border: 0.15rem solid;
  color: var(--primary-theme-color);
  border-color: var(--primary-theme-color);
  background-color: var(--secondary-theme-color);
}

#subscription .btn-primary:hover, #contact-form .btn-primary:hover {
  border: 0.15rem solid;
  color: var(--secondary-theme-color);
  border-color: var(--primary-theme-color);
  background-color: var(--primary-theme-color);
}

#subscription .btn.btn-primary:after, #contact-form .btn.btn-primary:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 102%;
  height: 100%;
  border-color: var(--primary-theme-color);
  z-index: -1;
  transition: ease 0.3s;
  background-color: var(--secondary-theme-color);
}

#subscription .form-control, #contact-form .form-control, #contact-form .form-check-input {
  border-radius: 0.2rem;
  border-color: var(--primary-theme-color);
  box-shadow: 0 0 0 0.05rem var(--primary-theme-color);
}

.was-validated .form-control:valid {
  background-image: none!important;
  padding-right: 0.75rem!important;
}


#subscription .form-control, #contact-form .form-floating, img, #nav-header .nav-link-button {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#subscription .form-control {
    padding-top: 0.7rem;
    padding-bottom: 0.77rem;
    margin-top: 0.1rem;
}

.subscription-form-group {
  height: fit-content;
}

#subscription .form-control:focus, #contact-form .form-control:focus, #contact-form .form-check-input:focus {
  border-color: var(--primary-theme-color);
  box-shadow: 0 0 0 0.2rem var(--primary-theme-color);
}

a {
  cursor: pointer;
}

a.nav-link-button {
  border-color: var(--secondary-theme-color) !important;
  color: var(--primary-theme-color) !important;
}

a.nav-link-button.active {
  border-color: var(--primary-theme-color) !important;
}

a.nav-link-button:not(.active):not(:active):hover {
  background-color: var(--primary-theme-color);

}

a.nav-link-button:not(.active):not(:active):hover {
  color: var(--secondary-theme-color) !important;
}

.toast {
  border-color: var(--primary-theme-color)!important;
  box-shadow: 0 0 0.1rem 0.1rem var(--primary-theme-color)!important;
  outline-width: 0;
}

#contact-form textarea {
  resize: none;
  overflow: hidden;
  height: 10rem;
}


#contact-form .form-check-input:checked {
  background-color: var(--primary-theme-color)!important;
}

.vision, .privacy-policy {
  background-color: var(--lighter-theme-color) !important;
  border-color: var(--primary-theme-color);
  box-shadow: 0 0 0.3rem 0.2rem var(--primary-theme-color)!important;
}

#privacy-policy-page p {
  text-align: justify;
}

#privacy-policy-page .heading {
  margin-top: 3rem;
  background: none;
  background-color: inherit !important;
  font-size: 2rem;
  color: var(--darker-theme-color);
}

#privacy-policy-page a:hover {
  color: var(--darker-theme-color) !important;
}

.contact-details {
  background-color: var(--lighter-theme-color) !important;
  color: var(--black-color);
}

.heading {
  background-color: var(--lighter-theme-color) !important;
}

.about-border, .services-border {
  border-color: var(--primary-theme-color);
  box-shadow: 0 0 0.1rem 0.1rem var(--primary-theme-color)!important;
}

.principle-card, .service-card, .sub-service-card {
  box-shadow: 0 0 0.1rem 0.1rem var(--primary-theme-color)!important;
  cursor: default;
}

.principle-card:hover, .service-card:hover, .sub-service-card:hover {
  background-color: var(--lighter-theme-color);
  box-shadow: 0 0 0.1rem 0.1rem var(--primary-theme-color)!important;
  cursor: default;
  transition: 0.3s;
}

.service-card:hover {
  cursor: pointer;
}

.principle-card:hover .principle-heading, .sub-service-card:hover .service-sub-heading {
  color: var(--secondary-theme-color);
}

.principle-heading, .service-sub-heading {
  color: var(--primary-theme-color);
  margin-bottom: 1.5rem!important;
}

.highlight {
  color: var(--black-color);
  text-shadow: 0 0 0.5rem var(--darker-theme-color);
}

.check-icon {
  color: var(--darker-theme-color) !important;
  text-shadow: none;
}

.follow-icon, .contact-icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--darker-theme-color);
  transition: font-size 0.1s ease;
}

.follow-icon:hover, .contact-icon:hover {
  font-size: 2rem;
}

.service-dropdown .btn-primary {
  background-color: var(--lighter-theme-color) !important;
  border: 0;
  color: var(--black-color) !important;
  font-size: 2.2rem;
  padding: 2.5rem;
  text-align: start;
}

.dropdown-toggle::after {
  vertical-align: 0.4rem!important;
}

.dropdown-item.active, .dropdown-item:focus {
  background-color: var(--primary-theme-color) !important;
}

.check-icon {
  color: var(--black-color);
  padding-right: 1rem;
  font-size: 1.2rem;
}

.navbar-collapse {
  padding-left: 2rem;
}

.navbar-toggler {
  margin-right: 0.6rem!important;
  border-color: var(--primary-theme-color) !important;
  box-shadow: 0 0 0.1rem 0.1rem var(--primary-theme-color)!important;
  padding: 0.2rem 0.5rem!important;
}

.navbar-toggler:hover {
  border-color: var(--primary-theme-color) !important;
  box-shadow: 0 0 0.2rem 0.2rem var(--darker-theme-color)!important;
}

.navbar-toggler-icon {
  background-image: url("./assets/hamburger-icon.svg")!important;
}

.not-found {
  height: 80vh;
}

.not-found .button {
  width: 10rem;
}


::-moz-selection {
  color: var(--secondary-theme-color);
  background: var(--primary-theme-color);
}

::selection {
  color: var(--secondary-theme-color);
  background: var(--primary-theme-color);
}

@media only screen and (max-width: 600px) {
  .mobile-height {
    height:90vh
  }

  a.nav-link-button.mobile-nav-item {
    padding-left: 1rem;
    margin-left: 1rem;
  }

  #subscription .btn-primary:hover {
    border: 1px solid;
    color: var(--primary-theme-color);
    border-color: var(--primary-theme-color);
    background-color: var(--secondary-theme-color);
  }

  .service-dropdown .btn-primary {
    font-size: 1.2rem;
    padding: 2.5rem 0rem;
  }

  .dropdown-toggle::after {
    vertical-align: 0.2rem!important;
  }

  #privacy-policy-page .privacy-content-main {
    margin: 0 2rem 0 2rem !important;
    padding: 0 !important;
  }

  .follow-icon, .contact-icon {
    transition: none;
  }

  .follow-icon:hover, .contact-icon:hover {
    font-size: 1.5rem;
  }
}
